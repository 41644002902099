import PropTypes from 'prop-types';

import withTheme from 'core/components/theme';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';

import AdWrapper from 'core/components/Ad/AdWrapper';
import TopicFooter from 'core/components/TopicFooter';
import { checkScrollToSiteInTopic } from 'core/components/ScrollToSite';

import { PartnersHorizontal } from 'site/components/Partners';
import Sponsored from 'site/components/Ads/Sponsored';
import Recommender from 'site/components/Recommender';
import { Indent, TopicContentMaxWidth } from 'core/components/Wrappers';

import { SuperFooter } from 'site/components/Ads/desktop';


function TopicFooterWrapper(props) {
  const {
    enhancedTopic,
    isDesktop,
    theme: {
      controls: {
        topicFooter: {
          block: {
            spacing,
          },
        },
      },
    },
  } = props;

  const {
    content,
    content: {
      attributes: {
        is_premium: isPremiumTopic,
      },
    },
    infinityIndex,
  } = enhancedTopic;

  const isScrollToSite = checkScrollToSiteInTopic(enhancedTopic);

  return (
    <>
      {!isPremiumTopic && (
        <TopicContentMaxWidth>
          <Sponsored />
          <Indent bottom={spacing}>
            <TopicFooter
              content={content}
              hrefBuilder={slug => `/label/${slug}`}
            />
          </Indent>
        </TopicContentMaxWidth>
      )}
      {!isScrollToSite && (
        <div data-qa='topic-footer'>
          {(isDesktop && !isPremiumTopic) && (
            <AdWrapper bottom={spacing}>
              <SuperFooter />
            </AdWrapper>
          )}
          <Indent bottom={isDesktop ? 0 : spacing}>
            {infinityIndex % 2 !== 0
              ? <Recommender />
              : <PartnersHorizontal />
            }
          </Indent>
        </div>
      )}
    </>
  );
}

TopicFooterWrapper.propTypes = {
  enhancedTopic: PropTypes.shape({
    infinityIndex: PropTypes.number,
    content: PropTypes.object,
  }),
  isDesktop: PropTypes.bool,
  theme: PropTypes.object,
};

export default withTheme(withBreakpoint(TopicFooterWrapper));
